<template>
  <div>

    <div class="container-fluid p-0" v-if="enableProductStatus">
      <div class="row">
        <div class="col-12 p-0 mb-2">
          <multiselect
            :placeholder="productPlaceholder"
            v-model="selectedProduct"
            :options="productOptions"
            :searchable="true"
            :loading="isLoading"
            track-by="id"
            label="name"
            @click="getProduct"
            @search-change="getProduct"
            :options-limit="300"
            :max-height="150"
            :max="3"
            @open="isOpen = true"
            @close="isOpen = false"
            name="selectedProduct"
          >
            <template slot="tag" slot-scope="{ option, remove }"
              ><span class="custom__tag"
                ><span>{{ option.name }}</span
                ><span class="custom__remove" @click="remove(option)"
                  >❌</span
                ></span
              ></template
            >
            <template slot="clear" slot-scope="props">
              <div
                class="multiselect__clear"
                v-if="selectedProduct.length"
                @mousedown.prevent.stop="clearAll(props.search)"
              ></div> </template
            ><span slot="noResult"
              >Oops! No products found. Consider changing the search
              query.</span
            >
          </multiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import multiselect from "vue-multiselect";
import axios from "axios";

export default {
  name: "Searchbar",
  components: {
    multiselect,
  },
  props:{
    enableProductStatus: Boolean
  },
  data: function() {
    return {
      selectedProduct: [],
      isLoading: false,
      productOptions: [],
      productPlaceholder: "Type product name or code",
    };
  },

  watch: {
    selectedProduct: function() {
      this.addProductToCart({
        product: this.selectedProduct,
        quantity: 1,
      });
    },
  },
  computed: {
    ...mapState("cart", ["cart"]),
  },

  methods: {
    ...mapActions({
      addProductToCart: "cart/addProductToCart",
    }),
    async getProduct(searchQuery) {
      this.isLoading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}product/getProduct?search=${
            searchQuery ? searchQuery : ""
          }`
        )
        .then((response) => {
          this.isLoading = false;
          if (response.data.statusCode == 200) {
            this.productOptions = [];
            response.data.data.forEach((element) => {
              this.productOptions.push({
                name: element.product_code + " - " + element.product_name,
                value: element.id,
                products: element,
              });
            });
          }
        });
    },
  },
  mounted() {
     this.getProduct();
  },
};
</script>

<style></style>
