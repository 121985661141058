<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <form>
          <div class="form-row">
            <div class="col-12">
              <form>
                <input
                  type="radio"
                  id="supplier"
                  name="people"
                  value="Supplier"
                  checked="checked"
                  class="mr-1"
                  @click="checkOptionsPeople(1)"
                />
                <label for="supplier" class="mr-3">Supplier</label>
                <input
                  type="radio"
                  id="manufacturers"
                  name="people"
                  class="mr-1"
                  value="Manufacturers"
                  @click="checkOptionsPeople(2)"
                />
                <label for="manufacturers">Manufacturers</label>
              </form>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="reference">
                  Reference
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control pl-2"
                  name="reference"
                  required
                  autocomplete="off"
                  placeholder="Reference"
                  v-model.trim="$v.details.reference.$model"
                  :class="{
                    'is-invalid': $v.details.reference.$error,
                    'is-valid': !$v.details.reference.$invalid,
                  }"
                />

                <div class="invalid-tooltip">
                  <span v-if="!$v.details.reference.required"
                    >Reference is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4" v-if="showPeople == 1">
              <div class="from-group">
                <div class="form-group">
                  <label for="supplier_id">
                    Suppliers
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control pl-1"
                    name="supplier_id"
                    v-model="supplier_id"
                    id="supplier_id"
                    :class="{
                      'is-invalid': $v.supplier_id.$error,
                      'is-valid': !$v.supplier_id.$invalid,
                    }"
                  >
                    <option value selected disabled>Select Supplier</option>
                    <option
                      v-for="item in supplierOptions"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.supplier_name }}</option
                    >
                  </select>
                  <div class="invalid-tooltip">
                    <span v-if="!$v.supplier_id.required"
                      >Supplier is required</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4" v-if="showPeople == 2">
              <div class="from-group">
                <div class="form-group">
                  <label for="supplier_id">
                    Manufacturers
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control pl-1"
                    v-model="manufacturer_id"
                    name="manufacturer_id"
                    id="manufacturer_id"
                    :class="{
                      'is-invalid': $v.manufacturer_id.$error,
                      'is-valid': !$v.manufacturer_id.$invalid,
                    }"
                  >
                    <option value selected>Select Manufacturer</option>
                    <option
                      v-for="item in manufacturerOptions"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.manufacturer_name }}</option
                    >
                  </select>
                  <div class="invalid-tooltip">
                    <span v-if="!$v.manufacturer_id.required"
                      >Manufacturer is required</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="form-group">
                <label for="date">
                  Date
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control px-2"
                  name="date"
                  required
                  value="2022-11-26"
                  v-model.trim="$v.details.date.$model"
                  :class="{
                    'is-invalid': $v.details.date.$error,
                    'is-valid': !$v.details.date.$invalid,
                  }"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.date.required">Date is required</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <table
              class="table table-sm table-responsive-md"
              v-if="enableProductStatus"
            >
              <thead class="dark">
                <tr>
                  <th col="span" class="text-center">Product</th>
                  <th col="span" class="text-center">
                    Net Unit Price ({{ this.$currency }})
                  </th>
                  <th col="span" class="text-center">Stock</th>
                  <th col="span" class="text-center">Quantity</th>
                  <th col="span" class="text-center">
                    Discount ({{ this.$currency }})
                  </th>
                  <th col="span" class="text-center">
                    Tax ({{ this.$currency }})
                  </th>
                  <th col="span" class="text-center">
                    Sub Total ({{ this.$currency }})
                  </th>
                  <th col="span" class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="cart.length == 0">
                  <td colspan="8" class="text-center">
                    <span class="text-danger"
                      >Please search & select products!</span
                    >
                  </td>
                </tr>
              </tbody>

              <tbody v-if="cart.length > 0">
                <tr v-for="item in cart" :key="item.product.products.id">
                  <td v-if="item.product.products.picture == null">
                    <div class="company-details">
                      <div class="company-logo">
                        <img src="../../assets/images/product-image.png" alt />
                      </div>
                      <h6 class="company-name">
                        {{ item.product.products.product_code }} -
                        {{ item.product.products.product_name }}
                      </h6>
                    </div>
                  </td>
                  <td v-else>
                    <div class="company-details">
                      <div class="company-logo">
                        <img
                          :src="API_URL + item.product.products.picture"
                          alt
                        />
                      </div>
                      <h6 class="company-name">
                        {{ item.product.products.product_code }} -
                        {{ item.product.products.product_name }}
                      </h6>
                    </div>
                  </td>

                  <td col="span" class="text-center">
                    {{ item.product.products.product_price }}
                  </td>
                  <td col="span" class="text-center">
                    {{ item.product.products.product_quantity }}
                    {{ item.product.products.product_unit }}
                  </td>
                  <td col="span" class="quantity-actions">
                    <span
                      class="item-decrement"
                      @click="productdecrement(item.product)"
                    >
                      <img src="../../assets/images/minux-grey.png" alt />
                    </span>
                    <span>{{ item.quantity }}</span>
                    <span
                      class="item-increment"
                      @click="productIncrement(item.product)"
                    >
                      <img src="../../assets/images/plus.png" alt />
                    </span>
                  </td>
                  <td col="span" class="text-center">
                    {{ item.product_discount_amount }}
                  </td>
                  <td col="span" class="text-center">
                    {{ item.product_tax_amount }}
                  </td>
                  <td col="span" class="text-center">{{ item.sub_total }}</td>
                  <td col="span" class="text-center">
                    <i
                      class="fa fa-trash"
                      @click="deleleItem(item.product)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row justify-content-md-end" v-if="enableProductStatus">
              <div class="col-md-4">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <tr>
                      <th>Order Tax ({{ orderTaxPercentage }}%)</th>
                      <td>(+) {{ this.$currency }}{{ orderTaxAmount }}</td>
                    </tr>
                    <tr>
                      <th>Discount ({{ discountPercentage }}%)</th>
                      <td>(-) {{ this.$currency }}{{ discountAmount }}</td>
                    </tr>
                    <tr>
                      <th>Total Amount</th>
                      <td>(+) {{ this.$currency }}{{ shipping }}</td>
                    </tr>
                    <tr>
                      <th>Grand Total</th>
                      <th>(=) {{ this.$currency }}{{ cartTotalPrice }}</th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="form-row">
              <!-- <div class="col-lg-4">
                <div class="form-group">
                  <label for="tax_percentage">Order Tax (%)</label>
                  <input
                    type="number"
                    class="form-control pl-2"
                    v-model="details.tax_percentage"
                    min="0"
                    max="100"
                    value="0"
                    @keyup="updateOrderPercentageValue($event.target.value)"
                    required
                  />
                </div>
              </div> -->
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="discount_percentage">Discount (%)</label>
                  <input
                    type="number"
                    class="form-control pl-2"
                    v-model="details.discount_percentage"
                    min="0"
                    max="100"
                    value="0"
                    @keyup="updateDiscountPercentageValue($event.target.value)"
                    required
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="shipping_amount"
                    >Total Amount ({{ this.$currency }})</label
                  >
                  <input
                    type="number"
                    class="form-control pl-2"
                    v-model="details.shipping_amount"
                    min="0"
                    @keyup="updateShippingValue($event.target.value)"
                    value="0"
                    required
                    step="0.01"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="paid_amount">
                    Amount Paid ({{ this.$currency }})
                    <span class="text-danger">*</span>
                  </label>

                  <input
                    id="paid_amount"
                    type="text"
                    class="form-control pl-2"
                    name="paid_amount"
                    v-model.trim="$v.details.paid_amount.$model"
                    :class="{
                      'is-invalid': $v.details.paid_amount.$error,
                      'is-valid': !$v.details.paid_amount.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.paid_amount.required"
                      >Paid Amount is required</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="status">
                  Status
                  <span class="text-danger">*</span>
                </label>
                <select
                  class="form-control pl-1"
                  name="status"
                  id="status"
                  v-model.trim="$v.details.status.$model"
                  :class="{
                    'is-invalid': $v.details.status.$error,
                    'is-valid': !$v.details.status.$invalid,
                  }"
                >
                  <option value selected>Select Status</option>
                  <option value="Pending">Pending</option>
                  <option value="Ordered">Ordered</option>
                  <option value="Completed">Completed</option>
                </select>
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.status.required"
                    >Status is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="payment_method">
                  Payment Method
                  <span class="text-danger">*</span>
                </label>
                <select
                  class="form-control pl-1"
                  name="payment_method"
                  id="payment_method"
                  v-model.trim="$v.details.payment_method.$model"
                  :class="{
                    'is-invalid': $v.details.payment_method.$error,
                    'is-valid': !$v.details.payment_method.$invalid,
                  }"
                >
                  <option value selected>Select Payment Method</option>
                  <option value="Cash">Cash</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Other">Other</option>
                </select>
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.payment_method.required"
                    >Payment Method is required</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 p-0">
            <div class="form-group">
              <label for="note">Note (if needed)</label>
              <textarea
                v-model="details.note"
                class="form-control"
                id="note"
                name="note"
                autocomplete="off"
              ></textarea>
            </div>
          </div>

          <!-- <div class="form-group">
                <label for="note">Note (If Needed)</label>
                <textarea
                  name="note"
                  id="note"
                  v-model="details.note"
                  class="form-control pl-2"
                ></textarea>
          </div>-->

          <div class="mt-3">
            <button
              type="button"
              class="custom-btn2"
              @click="CreatePayment(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Create Purchase</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  name: "PaymentPayableForm",
  props: {
    enableProductStatus: Boolean,
  },
  data() {
    return {
      showPeople: 1,
      supplierOptions: [],
      manufacturerOptions: [],
      details: {
        date: "",
        reference: "",
        tax_percentage: 0,
        discount_percentage: 0,
        shipping_amount: 0,
        paid_amount: 0,
        status: "",
        payment_status: "",
        payment_method: "",
        note: "",
        total_amount: 0,
        discount_amount: 0,
        tax_amount: 0,
      },
      supplier_id: "",
      manufacturer_id: "",
      API_URL: this.$productStorageURL,
      isLoadingArray: [],
    };
  },

  validations: {
    details: {
      date: {
        required,
      },
      reference: {
        required,
      },
      status: {
        required,
      },
      paid_amount: {
        required,
        numeric,
      },

      payment_method: {
        required,
      },
    },
    supplier_id: {
      required,
    },
    manufacturer_id: {
      required,
    },
  },
  methods: {
    ...mapActions({
      removeProductFromCart: "cart/removeProductFromCart",
      increaseQuantityFromCart: "cart/increaseQuantityFromCart",
      decreaseQuantityFromCart: "cart/decreaseQuantityFromCart",
      updateOrderTaxPer: "cart/updateOrderTaxPer",
      updateDiscountPer: "cart/updateDiscountPer",
      updateShipping: "cart/updateShipping",
       clearCartItems: "cart/clearCartItems",
    }),
    productIncrement(item) {
      this.increaseQuantityFromCart({
        product: item,
      });
    },
    productdecrement(item) {
      this.decreaseQuantityFromCart({
        product: item,
      });
    },
    deleleItem(item) {
      this.removeProductFromCart({
        product: item,
      });
    },
    updateShippingValue(value) {
      value = value ? value : 0;
      this.updateShipping({
        value: value,
      });
    },
    updateDiscountPercentageValue(value) {
      value = value ? value : 0;
      this.updateDiscountPer({
        value: value,
      });
    },
    updateOrderPercentageValue(value) {
      value = value ? value : 0;
      this.updateOrderTaxPer({
        value: value,
      });
    },
    callModal: function() {
      this.$root.$emit("openAddPaymentModal");
    },
    checkOptionsPeople(id) {
      this.showPeople = id;
    },

    getSupplier() {
      axios
        .get(`${process.env.VUE_APP_API_URL}supplier/getSupplier`)
        .then((response) => {
          this.supplierOptions = [];
          if (response.data.statusCode == 200) {
            this.supplierOptions = response.data.data;
          }
        });
    },

    getManufacturer() {
      axios
        .get(`${process.env.VUE_APP_API_URL}manufacturer/getManufacturer`)
        .then((response) => {
          this.manufacturerOptions = [];
          if (response.data.statusCode == 200) {
            this.manufacturerOptions = response.data.data;
          }
        });
    },

    CreatePayment: function(index) {
      this.details.total_amount = this.cartTotalPrice;
      this.details.discount_amount = this.discountAmount;
      this.details.tax_amount = this.orderTaxAmount;
      if (this.enableProductStatus == true) {
        this.details.cart = this.cart;
      } else {
        this.details.cart = [];
      }

      if (this.showPeople == 2) {
        this.$v.manufacturer_id.$touch();
        if (this.$v.manufacturer_id.$pendding || this.$v.manufacturer_id.$error)
          return;
        this.details.manufacturer_id = this.manufacturer_id;
        this.details.supplier_id = "";
      } else if (this.showPeople == 1) {
        this.$v.supplier_id.$touch();
        if (this.$v.supplier_id.$pendding || this.$v.supplier_id.$error) return;
        this.details.manufacturer_id = "";
        this.details.supplier_id = this.supplier_id;
      }

      this.$v.details.date.$touch();
      this.$v.details.reference.$touch();
      this.$v.details.status.$touch();
      this.$v.details.payment_method.$touch();
      this.$v.details.paid_amount.$touch();

      if (
        this.$v.details.date.$pendding ||
        this.$v.details.date.$error ||
        this.$v.details.reference.$pendding ||
        this.$v.details.reference.$error ||
        this.$v.details.status.$pendding ||
        this.$v.details.status.$error ||
        this.$v.details.payment_method.$pendding ||
        this.$v.details.payment_method.$error ||
        this.$v.details.paid_amount.$pendding ||
        this.$v.details.paid_amount.$error
      )
        return;
      this.$set(this.isLoadingArray, index, true);

      axios
        .post(
          process.env.VUE_APP_API_URL + "paymentPurchase/create",
          this.details
        )
        .then((response) => {
          this.$set(this.isLoadingArray, index, false);
          if (response.data.statusCode === 200) {
            this.$set(this.isLoadingArray, index, true);
            this.$router.push({ name: "PaymentPayable" });
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          this.$set(this.isLoadingArray, index, false);
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
  },

  computed: {
    ...mapState("cart", ["cart"]),
    ...mapGetters("cart", ["cartTotalPrice"]),
    ...mapState("cart", ["orderTaxPercentage"]),
    ...mapState("cart", ["discountPercentage"]),
    ...mapState("cart", ["shipping"]),
    ...mapGetters("cart", ["orderTaxAmount"]),
    ...mapGetters("cart", ["discountAmount"]),
  },
  mounted() {
    this.clearCartItems();
    this.getManufacturer();
    this.getSupplier();
  },
};
</script>

<style></style>
